import React, { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Box, Toolbar } from '@mui/material';
import {
  AdBoardTopBar,
  DiscussionsDrawer,
  SectionsDrawer,
} from '@keyops-hcp/ui-components';

import OutsideDrawer from './OutsideDrawer';
import { ADBOARD_SECTIONS } from '../../utils/routes';
import { getUrl } from '../../utils/functions/common-utils';
import { AdBoardLayoutOutletContext } from './AdBoardLayoutOutletContext';

const AdBoardLayoutWrapper = (): JSX.Element => {
  const navigate = useNavigate();
  const { adBoardId } = useParams();

  // outsideDrawer handling
  const [outsideDrawerOpen, setOutsideDrawerOpen] = useState(false);
  const toggleOutsideDrawer = (newOpen: boolean) => () => {
    setOutsideDrawerOpen(newOpen);
  };

  const [sectionDrawerOpen, setSectionDrawerOpen] = useState(true);
  const toggleSectionDrawer = () => {
    setSectionDrawerOpen((prevOpen) => !prevOpen);
  };

  // Discussions board nav handling
  const [discussionDrawerOpen, setDiscussionDrawerOpen] = useState(false);
  const toggleDiscussionDrawer = () => {
    setDiscussionDrawerOpen((prevOpen) => !prevOpen);
  };

  const setCurrentSectionId = (
    id: string,
    contentId?: string,
    gd?: boolean, // General Discussion
    msgId?: string
  ) => {
    // Construct the base URL
    let url = getUrl(ADBOARD_SECTIONS, {
      adBoardId,
      sectionId: id,
      contentId: contentId || '', // Used for navigating content within an activity (ex: topics in a discussion)
    });

    // Append #gd.msgId if gd is true & msgId is provided
    if (gd && msgId) {
      url += `#gd.${msgId}`;
    } else if (msgId) {
      // Append #msgId if msgId is provided but not gd
      url += `#${msgId}`;
    }

    // Navigate to the constructed URL
    navigate(url);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100vh'}>
      <AdBoardTopBar
        toggleOutsideDrawer={toggleOutsideDrawer}
        toggleDiscussionDrawer={toggleDiscussionDrawer}
        toggleSectionDrawer={toggleSectionDrawer}
        isSectionDrawerOpen={sectionDrawerOpen}
        isDiscussionDrawerOpen={discussionDrawerOpen}
      />
      <Toolbar sx={{ pb: '32px' }} />
      <Box
        data-testid="main-wrapper"
        flexGrow={1}
        display={'flex'}
        overflow={'hidden'}
        sx={{ backgroundColor: '#ffffff' }}
      >
        <Box
          component="main"
          flexGrow={1}
          overflow="auto"
          px={10}
          pt={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Need these functions to toggle the discussions drawer and update the current section id */}
          <Outlet
            context={
              {
                setCurrentSectionId,
                setDiscussionDrawerOpen,
              } satisfies AdBoardLayoutOutletContext
            }
          />
        </Box>
        <SectionsDrawer
          setCurrentSectionId={setCurrentSectionId}
          sectionDrawerOpen={sectionDrawerOpen}
          toggleSectionDrawer={toggleSectionDrawer}
        />
        <DiscussionsDrawer
          discussionDrawerOpen={discussionDrawerOpen}
          toggleDiscussionDrawer={toggleDiscussionDrawer}
        />
      </Box>
      <OutsideDrawer
        outsideDrawerOpen={outsideDrawerOpen}
        toggleOutsideDrawer={toggleOutsideDrawer}
      />
    </Box>
  );
};

export default AdBoardLayoutWrapper;
